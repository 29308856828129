<template>
<div class="list">
<div class="listMain wrap listSjMain">
<div class="listMainBox clearfloat">
<div class="listMainLeft fl">
<div class="tit">在线举报</div>
<div class="sort">
<div v-for="(item, index) in slideList" :key="index">
<a 
                v-if="item.url && item.url !== ''" 
                target="_blank" 
                :class="{ck : active  == item.entTChannelUuid}"
                :href="item.url">
                {{item.name}}
              </a>
<a 
                v-if="!item.url || item.url == ''" 
                :class="{ck : active  == item.entTChannelUuid}"
                :href="item.catalog === 1 ? `/listPage/${item.entTChannelUuid}` : item.catalog === 2 ? `/list/${item.entTChannelUuid}` : `/listPic/${item.entTChannelUuid}`">
                {{item.name}}
              </a>
</div>
</div>
</div>
<div class="listMainRight fr">
<div class="listWrap">
<div class="listHd clearfloat">
<div class="fl listHdGropL">
                在线举报
              </div>
<div class="fr listHdGrop">
                您当前的位置：<a href="/">首页</a>>
<span v-if="viewSlideHdParent"
                  ><a>在线举报</a></span
                >
</div>
</div>
<div class="listContainer listPicBox">
<div class="reportWrap">
<el-form ref="ruleForm" :model="form" :rules="rules" label-width="150px">
<div class="reportInner">
<div class="retportHd">举报人信息</div>
<div class="reportGrop" style="width: 60%;">
<el-form-item label="举报人姓名：" prop="jbUserName">
<el-input v-model="form.jbUserName" clearable placeholder="请输入举报人姓名"></el-input>
</el-form-item>
<el-form-item label="联系电话：" prop="jbTel">
<el-input v-model="form.jbTel" type="number" clearable placeholder="请输入联系电话"></el-input>
</el-form-item>
<el-form-item label="您的单位或地址：" prop="jbUserWorkUnit">
<el-input v-model="form.jbUserWorkUnit" clearable placeholder="请输入您的单位或地址"></el-input>
</el-form-item>
</div>
</div>
<div class="reportInner">
<div class="retportHd">被举报人信息</div>
<div class="reportGrop" style="width: 60%;">
<el-form-item label="被举报人姓名：" prop="bjbUserName">
<el-input v-model="form.bjbUserName" clearable placeholder="请输入被举报人姓名"></el-input>
</el-form-item>
<el-form-item label="被举报人单位：" prop="bjbUserWorkUnit">
<el-input v-model="form.bjbUserWorkUnit" clearable placeholder="请输入被举报人单位"></el-input>
</el-form-item>
</div>
</div>
<div class="reportInner">
<div class="retportHd">举报信息</div>
<div class="reportGrop" style="width: 60%;">
<el-form-item label="举报标题：" prop="jbTitle">
<el-input v-model="form.jbTitle" clearable placeholder="请输入举报标题"></el-input>
</el-form-item>
<el-form-item label="举报内容：" prop="jbContent">
<el-input
                          v-model="form.jbContent"
                          type="textarea"
                          :rows="4"
                          clearable
                          placeholder="请输入举报内容"
                        ></el-input>
</el-form-item>
<el-form-item label="验证码:" prop="code">
<el-input
                          v-model="form.code"
                          type="number"
                          clearable
                          placeholder="请输入验证码"
                          style="width: calc(100% - 150px)"
                        ></el-input>
<div class="codeImg">
<s-identify
                            style="display:inline-block; width: 100%; height:40px;vertical-align:top;cursor:pointer;"
                            :identifyCode="code"
                            :contentHeight="40"
                            :contentWidth="140"
                            @click.native="handleRefreshCode"
                          />
</div>
</el-form-item>
<el-form-item>
<el-button type="primary" :loading="loading" @click="submitForm('ruleForm')"
                          >立即提交</el-button
                        >
<el-button @click="resetForm('ruleForm')">重置</el-button>
</el-form-item>
</div>
</div>
</el-form>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</template>
<script>
import SIdentify from './captcha/index.vue'
import { getChildChannel, getChannelInfo, articles, addAccuseInform } from '@/api/common'

export default {
  components: {
    SIdentify,
  },
  name: 'list',
  data() {
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
      if (!value) {
        return callback(new Error('电话号码不能为空'))
      }
      setTimeout(() => {
        // Number.isInteger是es6验证数字是否为整数的方法,实际输入的数字总是识别成字符串
        // 所以在前面加了一个+实现隐式转换

        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('电话号码格式不正确'))
          }
        }
      }, 100)
    }
    return {
      slideHdTitle: '',
      viewSlideHdParent: true,
      slideHdParentName: '',
      slideHdParentUrl: '',
      slideHdName: '',
      hdTitle: '',
      hdName: '',
      pageCont: '',
      active: 0,
      slideHd: {
        title: '新闻动态'
      },
      slideList: [],
      pageSize: 9,
      pageNum: 1,
      total: 0,
      listData: [],
      listId: '',
      loading: false,
      code: '',
      form: {
        jbUserName: '',
        jbTel: '',
        jbUserWorkUnit: '',
        bjbUserName: '',
        bjbUserWorkUnit: '',
        jbTitle: '',
        jbContent: '',
        code: '',
        applicationUuid: this.webId
      },
      rules: {
        jbUserName: [{ required: true, message: '请输入举报人姓名', trigger: 'blur' }],
        jbTel: [
          { required: true, message: '请输入举报人电话', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' }
        ],
        bjbUserName: [{ required: true, message: '请输入被举报人姓名', trigger: 'blur' }],
        bjbUserWorkUnit: [{ required: true, message: '请输入被举报人单位', trigger: 'blur' }],
        jbContent: [{ required: true, message: '请输入举报内容', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    var _this = this
    _this.active = _this.$route.params.id
    _this.listId = _this.$route.params.id
    // _this.getChildChannel()
    _this.getChannelInfo()
    _this.articles()
    this.handleRefreshCode()
  },
  methods: {
    handleRefreshCode() {
      this.code = (Math.random() * 8999 + 1000).toFixed(0).toString()
    },
    submitForm(formName) {
      var _this = this
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loading = true
          if (this.form.code != this.code) {
            this.$message({
              type: 'warning',
              message: '验证码错误!'
            })
            this.loading = false
            return
          }
          addAccuseInform(_this.form).then(res => {
            const { code, msg } = res
            if (code === 1) {
              this.loading = false
              this.$confirm('提交成功, 我们会及时联系您！', '提示', {
                showConfirmButton: false,
                cancelButtonText: '取消',
                type: 'success',
                center: true
              })
                .then(() => {})
                .catch(() => {
                  _this.resetForm('ruleForm')
                })
            } else {
              this.loading = false
              this.$message({
                type: 'warning',
                message: msg + '提交失败，请稍后重试!'
              })
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    // 查询二级栏目
    getChildChannel(id) {
      var _this = this

      let data = {
        applicationUuid: _this.webId,
        parentUuid: id
      }
      getChildChannel(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.slideList = data
          if (_this.slideList.length) {
            _this.viewSlideHdParent = true
            let url = ''
            if (_this.slideList[0].catalog === 1) {
              url = `/listPage/${_this.slideList[0].entTChannelUuid}`
            } else if (_this.slideList[0].catalog === 2) {
              url = `/list/${_this.slideList[0].entTChannelUuid}`
            } else {
              url = `/listPic/${_this.slideList[0].entTChannelUuid}`
            }
            _this.slideHdParentUrl = url
          } else {
            _this.viewSlideHdParent = false
          }
        }
      })
    },
    getChannelInfo() {
      var _this = this
      let data = {
        channelUuid: _this.$route.params.id
      }
      getChannelInfo(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.slideHdName = data.name
          if (data.parentUuid == '0') {
            _this.slideHdTitle = data.name
            _this.viewSlideHdParent = false
            return
          }

          _this.getChildChannel(data.parentUuid)

          let params = {
            channelUuid: data.parentUuid
          }
          getChannelInfo(params).then(res => {
            const { code, data } = res
            if (code === 1 && data) {
              _this.slideHdParentName = data.name
              _this.slideHdTitle = data.name
            }
          })
        }
      })
    },
    // 获取列表
    articles() {
      var _this = this
      let data = {
        entTApplicationUuid: _this.webId,
        channelUuid: _this.$route.params.id,
        pageNum: _this.pageNum,
        pageSize: _this.pageSize
      }
      articles(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.listData = data.rows
          _this.total = data.totalRows
        }
      })
    },
    currentChange(e) {
      // console.log('e :>> ', e);
      var _this = this
      _this.pageNum = e
      _this.articles()
    }
  }
}
</script>
<style lang="css" scoped>
.listPicBox {
  padding-top: 20px;
}
.listPicInner {
  display: block;
  text-align: center;
  margin-bottom: 25px;
}
.listPicImg {
  height: 200px;
  overflow: hidden;
  border: solid 1px #0162b1;
  border-radius: 6px;
}
.listPicImg img {
  width: 100%;
  height: 100%;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.listPicInner:hover img {
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}
.listPicTitle {
  color: #070404;
  font-size: 18px;
  line-height: 48px;
}
.listPicMore {
  display: block;
  width: 80px;
  height: 32px;
  line-height: 32px;
  overflow: hidden;
  border: solid 1px #7b7b7b;
  margin: 0 auto;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.listPicMore:hover {
  color: #fff;
  background-color: #0162b1;
}
.reportWrap {
  padding: 6px;
  background-color: #f2f2f2;
}
.reportInner {
  background-color: #fff;
  padding-bottom: 2px;
  margin-bottom: 12px;
}
.reportInner:last-child {
  margin-bottom: 0;
}
.retportHd {
  height: 42px;
  line-height: 42px;
  padding: 0 15px;
  border-bottom: 1px solid #f6f6f6;
  color: #333;
  border-radius: 2px 2px 0 0;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
}
.codeImg {
  display: inline-block;
  width: 140px;
  height: 40px;
  margin-left: 10px;
}
</style>
